<template>
    <SectionLayout>
    <v-row class="px-5" style="background-color: #000055; padding-top: 20px; padding-bottom: 20px;">
      <v-col cols="12">
        <v-row justify="center" class="py-2">
          <v-col style="text-align: center">
            <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light', 'grey--text text--lighten-4']">Cryptium VPN Pricing</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--
    <v-row justify="center" class="px-5 pt-10 pb-0">
        <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="pa-3" style="text-align: center">
            <v-alert border="left" color="indigo" dark>
                Our VPN service is currently available for early adopters. What does this mean? We're still developing our service -- the features and quality of the service are not yet where we'd like them to be. For this reason, we are offering the VPN service at a deep discount in exchange for your patience and feedback.
            </v-alert>
        </v-col>
    </v-row>
    -->
    <!-- <v-row justify="center" class="px-5 pt-10 pb-0">
        <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="pa-3" style="text-align: center">
            <p>Select one of our plans to get started!</p>
        </v-col>
    </v-row> -->
    <!-- TODO: restore this monthly/annual switch when unicorn springs is ready to accept the price alias at signup so it selects the right one, and has good handling for switching between monthly and annual price for subscriptions (especially annual subscription with monthly resource metering, which might require creating two separatre subscriptions since all subscription items need ot hav ethe same billing period so in that case the monthly resource metering can't be together with the base subscription in stripe) -->
    <!-- <v-row justify="center" align="start" class="px-5">
        <v-switch
        color="blue darken-2"
        v-model="annualPricingMode"
        label="Show discounted pricing for annual billing plans"
        ></v-switch>
    </v-row> -->
    <v-row justify="center" class="px-5 pt-10 pb-10">
        <v-col class="ma-0 pa-0"  style="max-width: 860px;"><v-row justify="center" class="ma-0 pa-0">
        <!-- <v-col style="text-align: center" class="px-10"> -->
        <v-col cols="12" sm="8" md="4" lg="4" xl="4" class="pa-3" style="text-align: center">
            <v-card tile elevation="4" style="height: 100%" class="pricing-card">
                <v-system-bar color="green lighten-3" height="16"></v-system-bar>
                <v-card-title>
                    Subscription
                </v-card-title>
                <v-card-text class="px-5">
                    <p><span :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light', 'my-2']">
                        <!-- <span style="text-decoration: line-through;" class="mx-1">$9.00/month</span><br/> -->
                        <span class="mx-1">$4.99/month</span>
                    </span></p>
                    <!-- <p>Limited-time 60% discount for early adopters!</p> -->
                    <p>OpenVPN</p>
                    <p>Fast &amp; private browsing<!-- up to 40 Mbps --></p>
                    <p>Includes up to <strong>250</strong> GiB data transfer per month, with easy pricing for additional data transfers.</p>
                    <!-- <p>Access recovery (e.g. lost phone)</p>
                    <p>Community support</p> -->
                </v-card-text>
                <v-row justify="center">
                    <v-card-actions>
                        <!-- <v-btn :href="signupEssentialVpnURL" color="green darken-2 white--text">Select</v-btn> -->
                        <v-btn :to="{ name: 'signup', query: { product: 'essential-vpn'} }" color="green darken-2 white--text">Select</v-btn>
                    </v-card-actions>
                </v-row>
            </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="8" md="4" lg="4" xl="4" class="pa-3"  style="text-align: center">
            <v-card tile elevation="4" style="height: 100%" class="pricing-card">
                <v-system-bar color="green lighten-3" height="16"></v-system-bar>
                <v-card-title>
                    6-month plan
                </v-card-title>
                <v-card-text class="px-5">
                    <p><span :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light', 'my-2']">$3.99/month</span></p>
                    <p>OpenVPN</p>
                    <p>Unlimited browsing</p>
                </v-card-text>
                <v-row justify="center">
                    <v-card-actions>
                        <v-btn href="https://app.paykickstart.com/checkout/J5db4DnYoX3EajGr9dkAvwx6MG817LNe" color="green darken-2 white--text">Select</v-btn>
                    </v-card-actions>
                </v-row>
            </v-card>
        </v-col> -->
        <!-- <v-col cols="12" sm="8" md="4" lg="4" xl="4" class="pa-3"  style="text-align: center">
            <v-card tile elevation="4" style="height: 100%" class="pricing-card">
                <v-system-bar color="amber lighten-3" height="16"></v-system-bar>
                <v-card-title>
                    1-year plan
                </v-card-title>
                <v-card-text class="px-5">
                    <p><span :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline', 'font-weight-light', 'my-2']">
                        <span style="text-decoration: line-through;" class="mx-1">$108/year</span><br/>
                        <span class="mx-1">$37/year</span>
                    </span></p>
                    <p>Limited-time 65% discount for early adopters!</p>
                    <p>OpenVPN</p>
                    <p>Fast browsing</p>
                    <p>Includes up to <strong>250</strong> GiB data transfer per month, with easy pricing for additional data transfers.</p>
                </v-card-text>
                <v-row justify="center">
                    <v-card-actions>
                        <v-btn :href="signupUnlimitedVpnURL" color="amber darken-2 white--text">Select</v-btn>
                    </v-card-actions>
                </v-row>
            </v-card>
        </v-col> -->

        </v-row>

    <v-row justify="center" class="px-5">
        <v-col cols="12" sm="8" md="6" lg="4">
            <v-expansion-panels class="mt-8">
                <v-expansion-panel>
                    <v-expansion-panel-header>Data Transfer Pricing</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table dense class="mt-2">
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>After</th>
                                        <th>Monthly Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>250 GiB</td>
                                        <td>$0.10/GiB</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <!-- <p class="text-center purple--text text--lighten-4">All plans include up to 500 users. Additional users $0.05/month. Tiered pricing starts after 5,000 users. We only count monthly active users (mau) towards usage -- that means users who activated LibertyDNS and logged in at least once during the month.</p>
            <v-alert type="info">
            <span class="my-5">We are actively gathering feedback from new customers to add features and improve the product. You will get special direct access to our development team for your questions and comments.</span>
            </v-alert> -->
        </v-col>
    </v-row>

    <v-row justify="center" class="px-5 mt-15 mb-8">
        <v-col cols="12" sm="12" md="10" lg="8">
            <v-card elevation="4" class="px-2 py-2">
                <v-card-text>
                    <h3>Does the data transfer pricing mean you log my activity?</h3>
                    <p>
                        No. We do NOT log your online activity like what websites or servers you access
                        or what IP address you connected from.
                        We only measure how many bytes you transfer every day.
                    </p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

        </v-col>
    </v-row>
</SectionLayout>
</template>

<style lang="css" scoped>
h2 {
    font-size: 1.35em;
    color: #333333 !important; /* override h2 { color } from index.vue */
    margin-top: 1em;
    margin-bottom: 0.25em;
}
.pricing-card {
    padding-bottom: 52px;
}
.pricing-card .v-card__actions {
    position: absolute;
    bottom: 0;
}
.pricing-card td {
    vertical-align: top;
}
.pricing-card td p {
    padding-top: 1em;
}
.pricing-card table {
    width: 100%;
}
/* .pricing-card table tbody tr {
    border: none;
    margin: 0px;
    border-spacing: 0px;
} */
/* .pricing-card table tbody tr:hover {
    background-color: #dddddd;
} */
</style>

<script>
function linkWithTimestamp(href, query = {}) {
    const targetURL = new URL(href);
    Object.entries(query).forEach(([key, value]) => {
        targetURL.searchParams.set(key, value);
    });
    targetURL.searchParams.set('t', Date.now());
    return targetURL.toString();
}

export default {
    metaInfo() {
        return {
            title: 'Pricing',
            meta: [
                { name: 'description', content: 'Pricing' },
            ],
        };
    },
    data: () => ({
        annualPricingMode: false, // default is monthly pricing, visitor can switch to on/true for annual pricing
    }),
    computed: {
        signupURL() {
            // the admin web app now redirects to this pricing page with its sign up link, so from here we need to send customers to Unicorn Springs
            return process.env.GRIDSOME_UNICORNSPRINGS_SIGNUP_URL ?? 'https://customer.unicornsprings.com/brand/cryptium/signup';
        },
        signupEssentialVpnURL() {
            // return linkWithTimestamp('https://entry.libertybase.io/form/06MEGB23T122KR3BSFCG');
            return linkWithTimestamp(this.signupURL, { product_alias: 'essential-vpn' });
            // return linkWithTimestamp(this.signupURL, { product_alias: 'vpn-unlimited', price_alias: this.annualPricingMode ? 'yearly' : 'monthly' });
        },
        signupUnlimitedVpnURL() {
            // return linkWithTimestamp('https://entry.libertybase.io/form/06MEGB23T122KR3BSFCG');
            return linkWithTimestamp(this.signupURL, { product_alias: 'vpn-unlimited' });
            // return linkWithTimestamp(this.signupURL, { product_alias: 'vpn-unlimited', price_alias: this.annualPricingMode ? 'yearly' : 'monthly' });
        },
        signup20mbpsVpnURL() {
            return linkWithTimestamp(this.signupURL, { product_alias: 'vpn20' });
            // return linkWithTimestamp(this.signupURL, { product_alias: 'vpn20', price_alias: this.annualPricingMode ? 'yearly' : 'monthly' });
        },
        signup50mbpsVpnURL() {
            return linkWithTimestamp(this.signupURL, { product_alias: 'vpn50' });
            // return linkWithTimestamp(this.signupURL, { product_alias: 'vpn50', price_alias: this.annualPricingMode ? 'yearly' : 'monthly' });
        },
        signup100mbpsVpnURL() {
            return linkWithTimestamp(this.signupURL, { product_alias: 'vpn100' });
            // return linkWithTimestamp(this.signupURL, { product_alias: 'vpn100', price_alias: this.annualPricingMode ? 'yearly' : 'monthly' });
        },
    },
};
</script>
